<template>
  <v-card>
    <v-card-title>
      Invoice Drafts
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        @click.stop="addDraft()"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        <span>New Draft</span>
      </v-btn>
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              Customer
            </th>
            <th class="text-uppercase">
              Items
            </th>
            <th class="text-right text-uppercase">
              Total
            </th>
            <th class="text-center text-uppercase">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(draft,draftKey) in drafts"
            :key="draftKey"
          >
            <td>{{ draft.customer_name }}</td>
            <td class="">
              <ul>
                <li v-for="item in draft.items">
                  {{item.label}} - {{item.quantity}} ({{formatCurrency(item.unit_price)}})
                </li>
              </ul>
            </td>
            <td class="text-right">
              {{ formatCurrency(draft.total) }}
            </td>
            <td class="text-center">
              <v-icon
                small
                class=""
                @click="editDraft(draft)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
              &nbsp;
              <v-icon
                small
                class=""
                @click="deleteDraft(draftKey)"
              >
                {{ icons.mdiTrashCan }}
              </v-icon>
              &nbsp;
              <v-icon
                small
                class=""
                @click="createInvoiceFromDraft(draft,draftKey)"
              >
                {{ icons.mdiNotePlus }}
              </v-icon>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="text-uppercase" colspan="2">
              Total
            </th>
            <th class="text-right text-uppercase">
              {{ formatCurrency(draftTotal) }}
            </th>
            <th class="text-center text-uppercase">

            </th>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>

    <v-dialog
      v-model="isAddNewDraftModalActive"
      max-width="900px"
    >
      <draft-form
        :data="draftData"
        @close-dialog="isAddNewDraftModalActive = false"
        @refresh-list="fetchDrafts"
      ></draft-form>
    </v-dialog>
    <confirm ref="confirm"></confirm>
  </v-card>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiTrashCan,
  mdiNotePlus
} from '@mdi/js'
import DraftForm from './DraftForm.vue'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import invoiceStoreModule from '../invoiceStoreModule'
import Confirm from '@/components/Confirm.vue'
import { formatCurrency, formatNumber } from '@core/utils/filter'

export default {
  components: {
    DraftForm,
    Confirm
  },
  setup() {

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const isAddNewDraftModalActive = ref(false)

    const drafts = ref([])

    const draftData = ref({})

    const confirm = ref(null)

    const draftTotal = ref(0)

    const fetchDrafts = () => {

      store.dispatch('app-invoice/fetchDrafts', {

      }).then(response => {
        drafts.value = response.data
        for (var i = 0; i < response.data.length; i++) {
          draftTotal.value += response.data[i].total
        }
      }).catch(error => {
        console.log(error)
      })

    }

    fetchDrafts();

    const editDraft = (draft) => {
      draftData.value = draft
      isAddNewDraftModalActive.value = true
    }

    const deleteDraft = (key) => {

      confirm.value.open('Delete confirmation', 'Are you sure you want to delete this draft?').then((confirm) => {
        if (confirm) {
          store.dispatch('app-invoice/deleteDraft', drafts.value[key].id).then(response => {
            drafts.value.splice(key,1);
            store.dispatch("addNotification",{message:"Draft was removed.",type: "success"});
          }).catch(error => {
            console.log(error)
          })
        }
      })



    }

    const createInvoiceFromDraft = (draft,key) => {
      store.dispatch('app-invoice/createInvoiceFromDraft', draft).then(response => {
        drafts.value.splice(key,1);
        store.dispatch("addNotification",{message:"Invoice was created.",type: "success"});
      }).catch(error => {
        console.log(error)
      })
    }

    const addDraft = () => {
      draftData.value = {
        customer_id: 0,
        items: [
          {
            label: "",
            quantity: 0,
            unit_price: 0
          }
        ]
      }
      isAddNewDraftModalActive.value = true
    }

    return {
      drafts,
      draftData,
      draftTotal,
      isAddNewDraftModalActive,
      fetchDrafts,
      editDraft,
      addDraft,
      deleteDraft,
      confirm,
      formatCurrency,
      createInvoiceFromDraft,
      // icons
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiTrashCan,
        mdiNotePlus
      },
    }
  }
}
</script>

<style lang="css" scoped>
</style>
