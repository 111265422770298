<template>
  <v-card flat>
    <v-card-title>
      <span class="headline">Add / Edit draft</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        @click="$emit('close-dialog')"
      >
        <v-icon>
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-form>
        <v-autocomplete
          v-model="data.customer_id"
          outlined
          :menu-props="{ offsetY: true }"
          :items="customersList"
          :error-messages="errorMessages.customer_id"
          dense
          :filter="customerFilter"
          item-text="company_name"
          item-value="id"
          placeholder="Select Customer"
        ><template slot="selection" slot-scope="data">
          {{ data.item.company_name }} (<small>{{ data.item.contact_person }}</small>)
        </template>
        <template slot="item" slot-scope="data">
          {{ data.item.company_name }} (<small>{{ data.item.contact_person }}</small>)
        </template></v-autocomplete>

        <div class="add-products-header mb-2 d-none d-md-flex">
          <div class="flex-grow-1 font-weight-semibold">
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <span>Item</span>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-right"
              >
                <span>Quantity</span>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-right"
              >
                <span>Unit price</span>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-right"
              >
                <span>Price</span>
              </v-col>

            </v-row>
          </div>
          <div class="header-spacer"></div>
        </div>
        <div
          v-for="(draftItem, draftItemIndex) in data.items"
          :key="draftItemIndex"
        >
          <div class="flex-grow-1">
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model.number="draftItem.label"
                  outlined
                  dense
                  type="text"
                  hide-details="auto"
                  placeholder=""
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="2"
                sm="2"
              >
                <v-text-field
                  v-model.number="draftItem.quantity"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  @input="val => { draftItem.quantity = Math.abs(val) }"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="2"
                sm="2"
              >
                <v-text-field
                  v-model.number="draftItem.unit_price"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details="auto"
                  @input="val => { draftItem.unit_price = Math.abs(val) }"
                ></v-text-field>
              </v-col>


              <v-col
                cols="12"
                md="2"
                sm="2"
                class="text-right"
                style="align-self: center"
              >
                <span>{{ lineItemSum(draftItem) }}</span>

              </v-col>
              <v-col
                cols="12"
                md="2"
                sm="2"
                class="text-right"
                style="align-self: center"
              >
                <!-- Item Actions -->

                  <v-btn
                    icon
                    small
                    @click="data.items.splice(draftItemIndex, 1)"
                  >
                    <v-icon size="20">
                      {{ icons.mdiClose }}
                    </v-icon>
                  </v-btn>


              </v-col>
            </v-row>
          </div>
        </div>
        <v-btn
          color="primary"
          class="mt-5"
          outlined
          small
          @click="addNewItem"
        >
          Add Item
        </v-btn>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-5">
        <v-btn
          color="error"
          outlined
          @click="$emit('close-dialog')"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="saveDraft()"
        >
          Save
        </v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiPlus,
  mdiClose
} from '@mdi/js'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import invoiceStoreModule from '../invoiceStoreModule'
import { formatCurrency, formatNumber } from '@core/utils/filter'
import store from '@/store'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  setup(props, {emit}) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const customersList = ref([])
    const selectedCustomer = ref(null)

    const errorMessages = ref([])

    const lineItemSum = (line_item) => {

      return parseFloat(line_item.quantity * line_item.unit_price);

    }

    store.dispatch('app-invoice/fetchClients', {

    }).then(response => {
      customersList.value = response.data
    }).catch(error => {
      console.log(error)
    })

    const customerFilter = (item,queryText,itemText) => {
      return (
        item.company_name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.contact_person.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    }

    const addNewItem = () => {
      props.data.items.push({
          label: "",
          quantity: 0,
          unit_price: 0
      })
    }

    const saveDraft = () => {

      errorMessages.value = [];

      if (!props.data.hasOwnProperty('id')) {
        store
          .dispatch('app-invoice/addDraft', props.data)
          .then(response => {
            store.dispatch("addNotification",{message:"Draft was saved.",type: "success"});
            emit('close-dialog');
            emit('refresh-list');
          })
          .catch(error => {
            errorMessages.value = error.response.data.error
          })
      } else {
        store
          .dispatch('app-invoice/updateDraft', props.data)
          .then(response => {
            store.dispatch("addNotification",{message:"Draft was changed.",type: "success"});
            emit('close-dialog');
            emit('refresh-list');
          })
          .catch(error => {
            errorMessages.value = error.response.data.error
          })
      }
    }

    return {
      customersList,
      selectedCustomer,
      customerFilter,
      formatCurrency,
      lineItemSum,
      addNewItem,
      lineItemSum,
      saveDraft,
      errorMessages,

      icons: {
        mdiPlus,
        mdiClose
      },
    }
  }
}
</script>

<style lang="css" scoped>
</style>
